import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Loader from "./components/loader";
import Contact from "./contact";
import AboutUs from "./aboutus";
import CountryPage from "./country";
const Header = lazy(() => import("./components/header"));
const Footer = lazy(() => import("./components/footer"));
const Hero = lazy(() => import("./components/Hero"));
const Photocard = lazy(() => import("./components/Photocard"));

const WhatsAppButton = lazy(() => import("./components/whatsapp"));
const ArticleComponent = lazy(() => import("./components/article"));
const AddressSection = lazy(() => import("./components/address"));
const ImmigrationConsultantContainer = lazy(() =>
  import("./components/request")
);

function App() {
  

  return (
    <Router>
      <div className="App">
        <Suspense fallback={<Loader />}>
          <WhatsAppButton
            phoneNumber=""
            message="Hello! I would like more information."
          />
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Photocard />
                  <ArticleComponent />
                  <ImmigrationConsultantContainer />
                  <AddressSection />
                  
                </>
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/:country" element={<CountryPage />} />
            
          </Routes>
          <Footer />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
