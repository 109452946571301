import React from "react";
import emailjs from "emailjs-com";
import contactimg from "./assests/contact.png";

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log("Success:", result.text);
          alert("Message sent successfully!");
          e.target.reset();
        },
        (error) => {
          console.log("Error:", error.text);
          alert("Failed to send message.");
        }
      );
  };

  return (
    <div className="container p-3">
      <div className=" g-0  d-flex justify-content-center">
        {/* <div className="col-md-4">
          <img
            src={contactimg}
            alt="contact-us"
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            className="img-fluid"
          />
        </div> */}
        <div className="col-md-4">
          <div className="contact-form-2 p-3 w-100">
            <h2 style={{ color: "#fff" }}>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  id="name"
                  placeholder="Name"
                  name="from_name"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="email"
                  id="email"
                  name="from_email"
                  placeholder="Email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="tel"
                  id="number"
                  name="from_number"
                  placeholder="Mobile"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="inquiry" style={{ color: "#fff" }}>
                  Message
                </label>
                <textarea
                  id="inquiry"
                  name="message"
                  style={{ minHeight: "5rem" }}
                  className="form-control"
                  required
                ></textarea>
              </div>
              <div className="w-100 d-flex justify-content-end">
                <button
                  className="submit-button"
                  style={{ alignSelf: "end" }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            
          </div>
        </div>
        
      </div>
      
    </div>
  );
};

export default Contact;
